:root {
	--default-background-color: #fff;
	--container-background-color: #fff;
	--subcontainer-background-color: #eee;
	--default-margin: 30px;
	--default-radius: 4px;
	--primary-color: #007bff;
	--primary-font: 'Poppins', sans-serif;
	--text-color: #3c4257;
	--default-border-color: #dee2e6;
	--default-border-radius: 4px;
	--default-hover-background: #f2f2f2;
	--cart-icon-background-color: #f44336;
	--cart-icon-text-color: white;
	--modal-dialog-background-color: white;
	--modal-buttons-shaded-color: #f5f6fa;
	--modal-buttons-shaded-border-color: #ddd;
	--panel-background-color: #f5fafe;
	--success-background-color: #d5fdcc;
	--success-border-color: #93fb7c;
	--success-text-color: #62c73b;
	--link-color: #1a0dab;
}

* {
	margin: 0;
}

a {
	cursor: pointer;
}

.nav-tabs {
	border-bottom: 0 !important;
}

.rw-table {
	width: 100%;
}

.rw-table-header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid var(--default-border-color);
}

.rw-table-header .rw-th {
	padding: 10px;
	text-align: left;
	font-weight: bold;
}

.rw-table-header .rw-th:first-child {
	padding-left: 20px;
}

.rw-table-header .rw-th:last-child {
	padding-right: 20px;
}

.rw-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 0;
}

.rw-row:hover {
	background-color: #eeeeee;
}

.rw-row .rw-td {
	padding: 10px 10px;
	text-align: left;
	font-weight: normal;
	font-size: 14px;
}

.rw-row .rw-td:first-child {
	padding-left: 20px;
}

.rw-row .rw-td:last-child {
	padding-right: 20px;
}

.rw-table .action {
	text-align: right;
}

.rw-table .action .btn {
	min-height: initial;
	font-size: 14px;
	padding: 0.375rem 0.5rem;
}

.rw-table .action .btn i {
	/* margin-right: 10px; */
	color: #ffffff;
	font-size: 18px;
}

.rw-table .action .btn.btn-link i {
	/* margin-right: 10px; */
	color: var(--text-color);
}

.row label {
	margin-bottom: 5px;
}

form .row {
	margin-bottom: 20px;
	padding: 0 10px;
}

form .row label {
	margin-bottom: 5px;
}

form .form-field-image {
	border: 1px solid var(--default-border-color);
	padding: 3px;
}

.btn.btn-primary {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	filter: brightness(120%);
	box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	filter: brightness(120%);
}

.btn-primary.focus,
.btn-primary:focus {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	filter: brightness(120%);
	box-shadow: none;
}

.btn.btn-primary:hover {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	filter: brightness(120%);
}

.btn.btn-outline-primary {
	border-color: var(--primary-color);
	color: var(--primary-color);
}

.btn.btn-outline-primary:hover {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
	background-color: var(--primary-color);
	border-color: var(--default-background-color);
	color: var(--default-background-color);
	box-shadow: none;
}

.btn-link {
	color: var(--primary-color);
}

.btn-link:hover {
	color: var(--primary-color);
	filter: brightness(120%);
}

.btn-input-search {
	position: absolute;
	margin-left: -40px;
}

.Toastify__toast {
	padding: 8px 10px;
	border-radius: 10px;
	font-family: var(--primary-font);
	font-size: 14px;
}

.shadow-large {
	box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
}

.shadow-medium {
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-small {
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}
