.btn-searh-fonts:hover {
	color: #6c757d;
	opacity: .8;
}

.fonts-list {
	height: calc(100vh - 255px);
	overflow: auto;
	border: 1px solid var(--default-border-color);
	border-radius: .25rem;
}

.font-card {
	text-decoration: none;
	color: var(--bs-body-color);
	cursor: pointer;
}

.font-card-wrapper {
	padding: 10px;
	border: 1px solid transparent;
}

.font-card-wrapper:hover {
	background-color: #f0f0f0;
	color: var(--bs-body-color);
}

.font-card-wrapper .font-name {
	font-size: 13px;
}

.font-card-wrapper .font-preview {
	font-size: 20px;
}

.fonts-buttons-wrapper {
	position: fixed;
	bottom: 0;
	width: calc(30% - 40px);
}

.fonts-buttons {
	padding: 20px 0px;
}
