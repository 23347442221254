.header {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0 10px;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;
	background-color: #fff;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	border-bottom: 1px solid var(--default-border-color);
	z-index: 2;
}

.header img {
	max-height: 30px;
}

.header-nav {
	list-style: none;
	display: inline-flex;
	margin-bottom: 0;
}

.header-nav li a {
}
