.call-to-action-preview-container {
	height: calc(100vh - 160px);
}

.section-call-to-action {
	position: relative;
	background-position: center !important;
	background-size: cover !important;
	padding: 80px 0;
}

.section-call-to-action::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000000;
	opacity: 0.35;
	z-index: 1;
}

.section-call-to-action .headline {
	position: relative;
	font-size: 40px;
	color: #ffffff;
	z-index: 2;
}

.section-call-to-action .cta-button {
	position: relative;
	padding: 1rem 2rem;
	font-size: 22px;
	border-radius: 0;
	z-index: 2;
}
