.right-container .favicon-preview {
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;
	height: 100%;
	/* padding: 20px; */
	border: 1px solid var(--default-border-color);
	justify-content: center;
	align-items: center;
}

.right-container .favicon-preview .preview-area {
	display: flex;
	flex-direction: row;
	max-width: 90%;
	/* padding: 10px; */
	justify-content: center;
	align-items: center;
}

.right-container .favicon-preview .preview-area .icon-preview {
	/* margin-right: 10px;
	padding: 10px; */
}

.favicon-preview .preview-area .icon-preview img {
	filter: url(#faviconColorTransformFilter);
}
