.app-login-container {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
}

.app-login-container .login-column-container {
	max-width: 540px;
	width: 100%;
	text-align: left;
}

.app-login-container .login-box {
	background-color: white;
	/* margin-top: 30px; */
	padding: 50px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
}

.app-login-container .logo-box {
	text-align: left;
}

.app-login-container .logo-box img {
	display: block;
	max-height: 30px;
}
