.tab-content > .active {
	height: 100%;
}

.project-tab {
	border: 1px solid var(--default-border-color);
	height: calc(100vh - 170px);
}

.project-services-tab,
.project-blogposts-tab {
	border-top: 1px solid var(--default-border-color);
	height: calc(100vh - 218px);
	overflow: auto;
}

.project-services-tab > .active,
.project-blogposts-tab > .active {
	height: calc(100vh - 243px);
}

.project-hero-preview-container {
	/* border: 1px solid var(--default-border-color); */
	height: calc(100vh - 121px);
}

.project-logo-preview-area {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.project-logo-preview-area.vertical {
	flex-direction: column;
}

.project-logo-preview-area .icon-preview {
	margin-right: 10px;
	padding: 10px;
}

.project-logo-preview-area .icon-preview img {
	filter: url(#logoColorTransformFilter);
}

.project-section-hero {
	display: flex;
	min-height: 80%;
	align-items: center;
	text-align: center;
	border-top: 1px solid var(--default-border-color);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
}

.project-section-hero.backdrop {
	position: relative;
}

.project-section-hero.backdrop::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #0a0303;
	opacity: 0.5;
	z-index: 1;
}

.project-section-hero.backdrop > * {
	position: relative;
	z-index: 2;
}

.project-section-hero .headline,
.project-section-hero .subheadline {
	color: #ffffff;
	text-shadow: -1px -1px 0 #aaaaaa, 1px -1px 0 #aaaaaa, -1px 1px 0 #aaaaaa, 1px 1px 0 #aaaaaa;
	padding-left: 200px;
	padding-right: 200px;
}

.project-section-hero .subheadline {
	margin-top: 40px;
}

.project-section-hero .hero-buttons {
	padding-top: 100px;
}

.project-section-hero .hero-buttons .cta-button {
	padding: 1rem 2rem;
	font-size: 22px;
	border-radius: 0;
}

.project-section-service {
	display: flex;
	height: 40%;
	align-items: center;
	justify-content: center;
	background-position: center;
	background-size: cover;
	box-shadow: inset 2000px 0 0 0 rgba(10, 3, 3, 0.2);
}

.project-section-service.section-feat-image {
	width: 50%;
	height: 50%;
}

.project-section-service.section-feat-image .feat-image-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	background-position: center !important;
	background-size: cover !important;
}

.project-section-service .headline,
.project-section-service .subheadline {
	color: #ffffff;
	text-shadow: -1px -1px 0 #aaaaaa, 1px -1px 0 #aaaaaa, -1px 1px 0 #aaaaaa, 1px 1px 0 #aaaaaa;
}

.project-section-service .subheadline {
	margin-top: 40px;
}

.project-section-blogpost {
	display: flex;
	height: 50%;
	min-height: 300px;
	align-items: center;
	text-align: center;
	background-position: center;
	background-size: cover;
	box-shadow: inset 2000px 0 0 0 rgba(10, 3, 3, 0.2);
}

.project-section-blogpost .headline,
.project-section-blogpost .subheadline {
	color: #ffffff;
	text-shadow: -1px -1px 0 #aaaaaa, 1px -1px 0 #aaaaaa, -1px 1px 0 #aaaaaa, 1px 1px 0 #aaaaaa;
}

.project-section-blogpost .subheadline {
	margin-top: 40px;
}

/* Export container */
.export-container {
	position: absolute;
	left: -9999px;
	width: 70%;
	/* height: 1200px; */
	align-items: center;
	justify-content: center;
	z-index: 999999;
}

.export-container.about-us {
	height: calc(100vh - 160px);
}

.export-container .feat-image-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	background-position: center !important;
	background-size: cover !important;
}
