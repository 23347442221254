.images-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	height: 330px;
	padding: 10px;
	overflow-y: auto;
}

.images-container .image-container {
	position: relative;
}

.images-container .image-container img {
	width: 100%;
}

.images-container .image-container .close-image {
	position: absolute;
	top: 0;
	right: 5px;
	color: var(--text-color);
	text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;
}
