.projects-tab {
	height: calc(100vh - 200px);
}

.container-fluid.projects-table-container {
	display: flex;
}

.table-projects-list.rw-table .rw-table-data {
	height: calc(100vh - 297px);
	overflow-y: auto;
}

.table-projects-list.rw-table .name {
	width: 35%;
}

.table-projects-list.rw-table .industry {
	width: 13%;
}

.table-projects-list.rw-table .guid {
	width: 15%;
}

.table-projects-list.rw-table .submitted {
	width: 10%;
	text-align: center;
}

.table-projects-list.rw-table .completed {
	width: 12%;
	text-align: center;
}

.table-projects-list.rw-table .completed .progress {
	height: 1.25rem;
	font-size: 14px;
	background-color: #e0e0e0;
}

.table-projects-list.rw-table .completed .progress .progress-bar {
	background-color: #2fce12;
	transition: width 1s ease;
	transition-delay: 2s;
}

.table-projects-list.rw-table .completed .progress-number {
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.table-projects-list.rw-table .action {
	width: 15%;
}
