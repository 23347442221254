.main-app {
	min-height: 100vh;
}

.main-app .main-app-container {
	width: 100%;
	min-height: 100vh;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.content-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: calc(100vh - 70px);
	overflow-y: hidden;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;
	background-color: #ffffff;
}

.content-container .left-container {
	flex-direction: column;
	width: 30%;
	height: calc(100vh - 61px);
	border-right: 1px solid var(--default-border-color);
}

.content-container .right-container {
	flex-direction: column;
	width: 70%;
	height: calc(100vh - 61px);
	padding: 30px;
}

.form-fields-wrapper {
	padding: 20px;
	overflow-y: auto;
	height: calc(100vh - 148px);
}

.form-buttons-wrapper {
	position: fixed;
	bottom: 0;
	width: 30%;
	padding: 15px 20px;
	/* background-color: var(--modal-buttons-shaded-color); */
	border-top: 1px solid var(--modal-buttons-shaded-border-color);
}

.form-control-color {
	padding: 5px;
}

.search-list-wrapper {
	padding: 30px;
}

input[type=checkbox].check-completed {
	width: 20px !important;
	height: 20px !important;
	cursor: pointer;
}

input[type=checkbox].check-completed.list {
	width: 16px !important;
	height: 16px !important;
}

input[type=checkbox].check-completed.disabled {
	pointer-events: none;
}

/* icons list */
.btn-searh-icons:hover {
	color: #6c757d;
	opacity: .8;
}

.icons-list-wrapper {
	height: calc(100vh - 255px);
	overflow: auto;
	border: 1px solid var(--default-border-color);
	border-radius: .25rem;
}

.icons-list {
	padding: 10px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
}

.icons-list-view-more {
	display: flex;
	padding-bottom: 10px;
	align-items: center;
	justify-content: center;
}

.icon-card {
	text-decoration: none;
	color: var(--bs-body-color);
	cursor: pointer;
	width: 100%;
}

.icon-card.active {
	box-shadow: 0 0 0 2px rgb(6 24 44 / 40%), 0 4px 6px -1px rgb(6 24 44 / 65%), inset 0 1px 0 hsl(0deg 0% 100% / 8%);
}

.icon-card-wrapper {
	padding: 10px;
	border: 1px solid transparent;
}

.icon-card-wrapper:hover {
	background-color: #f0f0f0;
	color: var(--bs-body-color);
}

.icon-card-wrapper .icon-name {
	font-size: 13px;
}

.icon-card-wrapper .icon-preview {
	font-size: 20px;
}

.icon-card-wrapper .icon-preview .icon-image {
	width: 100%;
}

.icons-buttons-wrapper {
	position: fixed;
	bottom: 0;
	width: calc(30% - 40px);
}

.icons-buttons {
	padding: 20px 0px;
}

/* images list */
.btn-searh-images:hover {
	color: #6c757d;
	opacity: .8;
}

.images-list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	height: calc(100vh - 255px);
	padding: 10px;
	border: 1px solid var(--default-border-color);
	border-radius: .25rem;
	overflow: auto;
}

.image-card {
	height: 0;
	padding-bottom: 56.25%;
	background-size: cover;
	background-position: center;
	position: relative;
	overflow: hidden;
	background-color: #eee;
	text-decoration: none;
	color: var(--bs-body-color);
	border: 1px solid transparent;
	cursor: pointer;
}

.image-card:hover {
	background-color: #f0f0f0;
	color: var(--bs-body-color);
}

.image-card.active {
	box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
		rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.image-card .image-name {
	font-size: 13px;
}

.image-card .image-preview {
	font-size: 20px;
	width: 100%;
}

.image-card .image-preview img {
	position: absolute;
	min-width: 100%;
	left: 0%;
	top: 0;
}

.images-buttons-wrapper {
	position: fixed;
	bottom: 0;
	width: calc(30% - 40px);
}

.images-buttons {
	padding: 20px 0px;
}

.svg-image-preview {
	width: 0;
	height: 0;
	margin: 0;
	padding: 0;
	border: none;
}

@media screen and (max-width: 767px) {
	.actions-bar-container {
		display: block;
	}
}
