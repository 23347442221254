.testimonial-item-preview {
	width: 1200px;
	padding: 30px;
}

.testimonial-item-preview .img-testimonial {
	margin: 0 auto;
	width: 130px;
	height: 130px;
	border-radius: 50%;
	overflow: hidden;
	background-position: center;
	background-size: cover;
}

.testimonial-item-preview .testimonial-name {
	padding-top: 15px;
	margin-bottom: 0;
}

.testimonial-item-preview .testimonial-stars {
	margin-bottom: 10px;
}

.testimonial-item-preview .testimonial-stars i {
	color: #eed942;
}

.testimonial-item-preview .testimonial-position {
	/* color: var(--primary-color); */
	text-transform: uppercase;
}

.testimonial-item-preview .testimonial-text {
	padding-top: 15px;
	/* font-family: var(--content-font-family);
    font-size: var(--content-font-size);
    line-height: var(--content-line-height);
    color: var(--content-color);
    font-weight: var(--content-font-weight); */
}
