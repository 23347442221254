.app-404 {
	min-height: 100vh;
}

.app-404 .error-page-code {
	font-size: 100px;
	font-weight: bold;
}

.app-404 .btn {
	margin-top: var(--default-margin);
	min-height: auto;
}
