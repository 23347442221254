.container-blog-posts-list {
	border-radius: 0.25rem;
}

.container-blog-posts-list .blog-posts-list {
	display: flex;
}

.table-blog-posts-list.rw-table {
	border: 1px solid var(--default-border-color);
}

.table-blog-posts-list.rw-table .rw-table-header .rw-th {
	font-weight: normal;
}

.table-blog-posts-list.rw-table .rw-table-data {
	max-height: 135px;
	overflow-y: auto;
}

.table-blog-posts-list.rw-table .completed {
	width: 10%;
	text-align: center;
}

.table-blog-posts-list.rw-table .name {
	width: 65%;
}

.table-blog-posts-list.rw-table .action {
	width: 25%;
}
