.hero-preview-container {
	border: 1px solid var(--default-border-color);
	height: calc(100vh - 121px);
}

.logo-preview-area {
	display: flex;
	flex-direction: row;
	max-width: 90%;
	padding: 10px;
	justify-content: center;
	align-items: center;
}

.logo-preview-area.vertical {
	flex-direction: column;
}

.logo-preview-area .icon-preview {
	margin-right: 10px;
	padding: 10px;
}

.logo-preview-area .icon-preview img {
	max-height: 80px;
	filter: url(#logoColorTransformFilter);
}

.section-hero {
	display: flex;
	min-height: 80%;
	align-items: center;
	text-align: center;
	border-top: 1px solid var(--default-border-color);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
}

.section-hero.backdrop {
	position: relative;
}

.section-hero.backdrop::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #0a0303;
	opacity: 0.5;
	z-index: 1;
}

.section-hero.backdrop > * {
	position: relative;
	z-index: 2;
}

.section-hero .headline,
.section-hero .subheadline {
	color: #ffffff;
	text-shadow: -1px -1px 0 #aaaaaa, 1px -1px 0 #aaaaaa, -1px 1px 0 #aaaaaa, 1px 1px 0 #aaaaaa;
}

.section-hero .subheadline {
	margin-top: 40px;
}

.section-hero .hero-buttons {
	padding-top: 100px;
}

.section-hero .hero-buttons .cta-button {
	padding: 1rem 2rem;
	font-size: 22px;
	border-radius: 0;
}
