.testimonial-preview-container {
	/* border: 1px solid var(--default-border-color); */
	height: calc(100vh - 160px);
}

.section-testimonial {
	display: flex;
	height: 100%;
	max-height: 400px;
	align-items: center;
	text-align: center;
	background-position: center;
	background-size: cover;
	box-shadow: inset 2000px 0 0 0 rgba(10, 3, 3, 0.1);
}

.section-testimonial .headline,
.section-testimonial .subheadline {
	color: #ffffff;
	text-shadow: -1px -1px 0 #aaaaaa, 1px -1px 0 #aaaaaa, -1px 1px 0 #aaaaaa, 1px 1px 0 #aaaaaa;
	padding-left: 200px;
	padding-right: 200px;
}

.section-testimonial .subheadline {
	margin-top: 40px;
}
