form .row {
	margin-bottom: 20px;
	padding: 0 10px;
}

form .row label {
	margin-bottom: 5px;
}

form .form-field-image {
	border: 1px solid var(--default-border-color);
}
