.container-services-list {
	border-radius: 0.25rem;
}

.container-services-list .services-list {
	display: flex;
}

.table-services-list.rw-table {
	border: 1px solid var(--default-border-color);
}

.table-services-list.rw-table .rw-table-header .rw-th {
	font-weight: normal;
}

.table-services-list.rw-table .rw-table-data {
	max-height: 135px;
	overflow-y: auto;
}

.table-services-list.rw-table .completed {
	width: 10%;
	text-align: center;
}

.table-services-list.rw-table .name {
	width: 65%;
}

.table-services-list.rw-table .action {
	width: 25%;
}
