.aboutus-preview-container {
	height: calc(100vh - 160px);
}

.section-aboutus {
	position: relative;
	background-position: center !important;
	background-size: cover !important;
}

.aboutus-preview-container .section-aboutus .preview-area {
	display: flex;
	flex-direction: row;
	/* max-width: 90%; */
	padding: 10px;
	justify-content: center;
	align-items: center;
}

.aboutus-preview-container .section-aboutus .preview-area.vertical {
	flex-direction: column;
}

.aboutus-preview-container .section-aboutus .preview-area .icon-preview {
	margin-right: 10px;
	padding: 10px;
}

.section-aboutus .icon-preview img {
	filter: url(#aboutUsColorTransformFilter);
}
