.project-button-checkmark {
	z-index: 1;
}

.project-button-checkmark i {
	font-size: 20px;
	color: #ffffff;
}

.project-button-checkmark i:hover {
	color: #ffffff;
}

.delete-project-wrapper {
	position: absolute;
	top: 11px;
	right: 30px;
}

.publish-items-wrapper {
	max-height: 200px;
	padding: 5px 0;
	overflow-y: auto;
	overflow-x: hidden;
	border: 1px solid #ddd;
}

.publish-items-wrapper .row label {
	margin-left: -4px;
	margin-bottom: 2px;
	min-width: 100px;
}

.publish-items-wrapper .form-check-inline {
	margin-right: 25px;
	margin-bottom: 0;
}

.publish-items-wrapper .form-check-inline input[type=checkbox].checkbox-for-image {
	margin-top: 22px;
}

.publish-items-wrapper .form-check-inline img {
	border: 1px solid #dddddd;
}
