.aboutus-preview-container {
	height: calc(100vh - 160px);
}

.section-aboutus {
	position: relative;
	background-position: center !important;
	background-size: cover !important;
	padding: 0;
}

.aboutus-preview-container .section-aboutus .preview-area {
	display: flex;
	flex-direction: row;
	padding: 10px;
	justify-content: center;
	align-items: center;
}

.aboutus-preview-container .section-aboutus .about-us-background {
	text-align: center;
	background-position: center;
	background-size: cover;
	background-color: var(--aboutus-background);
	width: 100%;
	height: 100%;
	z-index: 1;
}

.iframe-fluid {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
}

.aboutus-preview-container .section-aboutus .about-us-details {
	padding: 40px;
}
