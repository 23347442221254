.service-preview-container {
	/* border: 1px solid var(--default-border-color); */
	height: calc(100vh - 160px);
}

.section-service {
	display: flex;
	height: 40%;
	align-items: center;
	text-align: center;
	background-position: center;
	background-size: cover;
	box-shadow: inset 2000px 0 0 0 rgba(10, 3, 3, 0.2);
}

.section-service.section-feat-image {
	position: relative;
	max-width: 50%;
	height: 60%;
	background-position: center !important;
	background-size: cover !important;
}

.section-service.section-feat-image .icon-preview {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.section-service.section-feat-image .icon-preview img {
	filter: url(#serviceColorTransformFilter);
}

.section-service .headline,
.section-service .subheadline {
	color: #ffffff;
	text-shadow: -1px -1px 0 #aaaaaa, 1px -1px 0 #aaaaaa, -1px 1px 0 #aaaaaa, 1px 1px 0 #aaaaaa;
	padding-left: 200px;
	padding-right: 200px;
}

.section-service .subheadline {
	margin-top: 40px;
}

.services-tab {
	border: 1px solid var(--default-border-color);
	height: calc(100vh - 160px);
}
