.modal-dialog-container {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
	justify-content: center;
	align-items: center;
	z-index: 99;
}

.modal-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .6);
	z-index: 98;
}

.modal-dialog-container .modal-dialog {
	margin: 0;
	position: relative;
	max-width: 50%;
	width: 100%;
	background-color: var(--modal-dialog-background-color);
	border-radius: var(--default-border-radius);
	pointer-events: initial;
	overflow: hidden;
}

.modal-dialog-container.full-screen > .modal-dialog {
	margin: 0;
	position: relative;
	max-width: 95%;
	width: 100%;
	height: 90vh;
	background-color: var(--modal-dialog-background-color);
	border-radius: var(--default-border-radius);
	pointer-events: initial;
	overflow: hidden;
}

.modal-dialog-container.full-screen > .modal-dialog > .modal-dialog-content {
	height: 100%;
	overflow-y: auto;
	position: relative;
}

@media screen and (max-width: 767px) {
	.modal-dialog-container .modal-dialog {
		max-width: 90%;
	}
}
